@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'regular';
  font-style: normal;
  font-weight: 400;
  src: url("./assets/font/GraphikCompact/GraphikCompact-Regular.otf") format("opentype");
}
@font-face {
  font-family: 'thin';
  font-weight: 400;
  src: url("./assets/font/GraphikCompact/GraphikCompact-Light.otf") format("opentype");
}
@font-face {
  font-family: 'light';
  font-weight: 400;
  src: url("./assets/font/GraphikCompact/GraphikCompact-Light.otf") format("opentype");
}
@font-face {
  font-family: 'bold';
  font-weight: 400;
  src: url("./assets/font/GraphikCompact/GraphikCompact-Bold.otf") format("opentype");
}
@font-face {
  font-family: 'italic';
  font-weight: 400;
  src: url("./assets/font/GraphikCompact/GraphikCompact-LightItalic.otf") format("opentype");
}
@font-face {
  font-family: 'italic-bold';
  font-weight: 400;
  src: url("./assets/font/GraphikCompact/GraphikCompact-MediumItalic.otf") format("opentype");
}
  html {
    scroll-behavior: smooth;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  * {
    color: #212529;
  }